// Generated by Waypoint
// Do not edit manually!

const doNotEncodeSymbol = Symbol("DoNotEncode");

type UrlValue = string | number | boolean;

type DoNotEncode = {
  [doNotEncodeSymbol]: true;
  value: UrlValue;
};

function doNotEncode(value: UrlValue): DoNotEncode {
  return { [doNotEncodeSymbol]: true, value };
}

// Tagged template literal function to encode URL components
function encodeValues(
  strings: TemplateStringsArray,
  ...values: (UrlValue | DoNotEncode)[]
) {
  // Process each value to encode it
  const encodedValues = values.map(value =>
    typeof value === "object" && doNotEncodeSymbol in value
      ? value.value
      : encodeURIComponent(value)
  );

  // Combine the strings and encoded values
  let result = strings[0];
  encodedValues.forEach((value, index) => {
    result += value + strings[index + 1];
  });

  return result;
}
export const organisationUploadedFiles = (orgType: string, orgId: string) =>
  encodeValues`/api/${orgType}/${orgId}/uploaded-files`;
export const organisationUploadedFilesFile = (
  orgType: string,
  orgId: string,
  fileId: string
) => encodeValues`/api/${orgType}/${orgId}/uploaded-files/${fileId}`;
export const tfa = () => encodeValues`/api/2fa`;
export const tfaEmailAddress = (emailAddress: string) => {
  let url = encodeValues`/api/2fa`;
  url += encodeValues`?emailAddress=${emailAddress}`;
  return url;
};
export const tfaCodes = () => encodeValues`/api/2fa/codes`;
export const acceptTerms = () => encodeValues`/api/accept-terms`;
export const announcements = () => encodeValues`/api/announcements`;
export const announcement = (announcementId: string) =>
  encodeValues`/api/announcements/${announcementId}`;
export const announcementImage = (announcementId: string) =>
  encodeValues`/api/announcements/${announcementId}/image`;
export const changePassword = () => encodeValues`/api/change-password`;
export const errorLog = () => encodeValues`/api/error-log`;
export const forgotPassword = () => encodeValues`/api/forgot-password`;
export const help = () => encodeValues`/api/help`;
export const image = (path: string) =>
  encodeValues`/api/images/${doNotEncode(path)}`;
export const rootLayout = () => encodeValues`/api/layout`;
export const markSchemeTypes = () => encodeValues`/api/mark-scheme-types`;
export const schools = () => encodeValues`/api/schools`;
export const school = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}`;
export const schoolAccessRecord = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/access-record`;
export const schoolBulkPupilDeletion = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/bulk-pupil-deletion`;
export const schoolClassTeachers = (schoolId: string, academicYear: string) =>
  encodeValues`/api/schools/${schoolId}/admin/class-teachers/${academicYear}`;
export const schoolClassTeachersSignature = (
  schoolId: string,
  academicYear: string,
  className: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/class-teachers/${academicYear}/${className}/signature`;
export const schoolClassTeachersCopyFrom = (
  schoolId: string,
  academicYear: string,
  fromAcademicYear: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/admin/class-teachers/${academicYear}/copy-from`;
  url += encodeValues`?fromAcademicYear=${fromAcademicYear}`;
  return url;
};
export const schoolClasses = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/classes`;
export const schoolClassesExcel = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/classes.xslx`;
export const schoolAdminMarkSchemes = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes`;
export const schoolAdminMarkScheme = (schoolId: string, markSchemeId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/${markSchemeId}`;
export const schoolAdminMarkSchemeJson = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/${markSchemeId}/json`;
export const schoolAdminMarkSchemesDefault = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/default`;
export const schoolAdminMarkSchemesJson = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/json`;
export const schoolAdminMarkSchemesAge = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Age/${markSchemeId}`;
export const schoolAdminMarkSchemesAverageDepth = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/AverageDepth/${markSchemeId}`;
export const schoolAdminMarkSchemesBand = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Band/${markSchemeId}`;
export const schoolAdminMarkSchemesBandConversionStatus = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Band/${markSchemeId}/conversion-status`;
export const schoolAdminMarkSchemesBandConvert = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Band/${markSchemeId}/convert`;
export const schoolAdminMarkSchemesDecimal = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Decimal/${markSchemeId}`;
export const schoolAdminMarkSchemesDevelopmentMatters = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/DevelopmentMatters/${markSchemeId}`;
export const schoolAdminMarkSchemesDevelopmentMattersConversionStatus = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/DevelopmentMatters/${markSchemeId}/conversion-status`;
export const schoolAdminMarkSchemesDevelopmentMattersConvert = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/DevelopmentMatters/${markSchemeId}/convert`;
export const schoolAdminMarkSchemesKsSatScaledScores = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/KsSatScaledScores/${markSchemeId}`;
export const schoolAdminMarkSchemesMtc = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Mtc/${markSchemeId}`;
export const schoolAdminMarkSchemesPhonics = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Phonics/${markSchemeId}`;
export const schoolAdminMarkSchemesPitaBands = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/PitaBands/${markSchemeId}`;
export const schoolAdminMarkSchemesPitaBandsBand = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/PitaBands/${markSchemeId}/Band`;
export const schoolAdminMarkSchemesRawScore = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/RawScore/${markSchemeId}`;
export const schoolAdminMarkSchemesStandardisedScores = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/StandardisedScores/${markSchemeId}`;
export const schoolAdminMarkSchemesSteps = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Steps/${markSchemeId}`;
export const schoolAdminMarkSchemesStepsExcel = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Steps/${markSchemeId}/excel`;
export const schoolAdminMarkSchemesStepsStep = (
  schoolId: string,
  markSchemeId: string
) =>
  encodeValues`/api/schools/${schoolId}/admin/mark-schemes/types/Steps/${markSchemeId}/step`;
export const schoolAdminPupils = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/pupils`;
export const schoolAdminPupilsList = (
  schoolId: string,
  archive?: string,
  filter?: string,
  page?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/admin/pupils`;
  let anyQueryParams = false;
  if (archive != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`archive=${archive}`;
    anyQueryParams = true;
  }
  if (filter != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`filter=${filter}`;
    anyQueryParams = true;
  }
  if (page != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`page=${page}`;
    anyQueryParams = true;
  }
  return url;
};
export const schoolRoleCosts = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/role-costs`;
export const schoolRoleCost = (schoolId: string, roleId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/role-costs/${roleId}`;
export const schoolStaffCosts = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/staff-costs`;
export const schoolStaffCost = (schoolId: string, userId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/staff-costs/${userId}`;
export const schoolSubjects = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/admin/subjects`;
export const schoolAssessmentFrequency = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-frequency`;
export const schoolAssessmentRestore = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-restore`;
export const schoolAssessmentTransfer = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-transfer`;
export const schoolAssessmentTransferSearch = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-transfer/search`;
export const schoolAssessmentTypes = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-types`;
export const schoolAssessmentType = (
  schoolId: string,
  assessmentTypeName: string
) =>
  encodeValues`/api/schools/${schoolId}/assessment-types/${assessmentTypeName}`;
export const schoolAssessmentTypeComponents = (
  schoolId: string,
  assessmentTypeName: string
) =>
  encodeValues`/api/schools/${schoolId}/assessment-types/${assessmentTypeName}/components`;
export const schoolAssessmentTypeComponent = (
  schoolId: string,
  assessmentTypeName: string,
  assessmentComponentName: string
) =>
  encodeValues`/api/schools/${schoolId}/assessment-types/${assessmentTypeName}/components/${assessmentComponentName}`;
export const schoolAssessmentTypesCreate = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-types/create`;
export const schoolAssessmentTypesRenameStatus = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessment-types/rename-status`;
export const schoolAssessments = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessments`;
export const schoolAssessmentsAdmin = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessments/admin`;
export const schoolAssessmentsAdminDelete = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessments/admin/delete`;
export const schoolAssessmentsAdminMarkScheme = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessments/admin/mark-scheme`;
export const schoolAssessmentsCopyFromPrevious = (
  schoolId: string,
  pupilQuery: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/assessments/copy-from-previous`;
  url += encodeValues`?pupilQuery=${pupilQuery}`;
  return url;
};
export const schoolAssessmentsValidateMark = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessments/validate-mark`;
export const schoolAssessmentsValidateThresholds = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/assessments/validate-thresholds`;
export const schoolAttainmentEvaluationDescriptions = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/attainment-evaluation-descriptions`;
export const schoolAttendanceThresholds = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/attendance-thresholds`;
export const schoolAudit = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/audit-entries`;
export const schoolExport = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/export`;
export const schoolExportDownload = (
  schoolId: string,
  expires: string,
  nonce: string,
  sig: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/export/download`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`expires=${expires}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`nonce=${nonce}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`sig=${sig}`;
  anyQueryParams = true;
  return url;
};
export const schoolEyfs = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/eyfs`;
export const schoolEyfsCtf = (
  schoolId: string,
  ctfDestination: string,
  pupilQuery: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/eyfs/ctf`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`ctfDestination=${ctfDestination}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`pupilQuery=${pupilQuery}`;
  anyQueryParams = true;
  return url;
};
export const schoolEyfsExcel = (schoolId: string, pupilQuery: string) => {
  let url = encodeValues`/api/schools/${schoolId}/eyfs/excel`;
  url += encodeValues`?pupilQuery=${pupilQuery}`;
  return url;
};
export const schoolEyfsGenerateFromMainAssessments = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/eyfs/generate-from-main-assessments`;
export const schoolFiles = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/files`;
export const schoolFile = (schoolId: string, path: string) =>
  encodeValues`/api/schools/${schoolId}/files/${doNotEncode(path)}`;
export const schoolPrivateMode = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/governor-mode`;
export const schoolHeadlineReports = (schoolId: string, owner?: string) => {
  let url = encodeValues`/api/schools/${schoolId}/headline-reports`;
  if (owner != null) {
    url += encodeValues`?owner=${owner}`;
  }
  return url;
};
export const schoolHeadlineReport = (schoolId: string, reportId: string) =>
  encodeValues`/api/schools/${schoolId}/headline-reports/${reportId}`;
export const schoolHeadlineReportsExcel = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/headline-reports/excel`;
export const schoolHeadlineReportsLinks = (
  schoolId: string,
  owner: string,
  page?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/headline-reports/links/${owner}`;
  if (page != null) {
    url += encodeValues`?page=${page}`;
  }
  return url;
};
export const schoolHeadlineReportsRender = (
  schoolId: string,
  isPreview?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/headline-reports/render`;
  if (isPreview != null) {
    url += encodeValues`?isPreview=${isPreview}`;
  }
  return url;
};
export const schoolHeadlineReportsRenderJob = (
  schoolId: string,
  jobId: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/headline-reports/render`;
  url += encodeValues`?jobId=${jobId}`;
  return url;
};
export const schoolImportExcelAssessments = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/import-excel-assessments`;
export const schoolImportExcelAssessmentsParse = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/import-excel-assessments/parse`;
export const schoolImportExcelAssessmentsSave = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/import-excel-assessments/save`;
export const schoolImportPupils = (schoolId: string, academicYear?: string) => {
  let url = encodeValues`/api/schools/${schoolId}/import-pupils`;
  if (academicYear != null) {
    url += encodeValues`?academicYear=${academicYear}`;
  }
  return url;
};
export const schoolImportPupilsImport = (
  schoolId: string,
  importId: string,
  status?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/import-pupils/${importId}`;
  if (status != null) {
    url += encodeValues`?status=${status}`;
  }
  return url;
};
export const schoolKeyGroups = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/key-groups`;
export const schoolKs2SatCtf = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/ks2-sat-scaled-scores`;
export const schoolKs2SatCtfParse = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/ks2-sat-scaled-scores/parse`;
export const schoolKs2SatCtfSave = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/ks2-sat-scaled-scores/save`;
export const schoolLayout = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/layout`;
export const schoolLearningPlanTemplates = (
  schoolId: string,
  includeArchived?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/learning-plan-templates`;
  if (includeArchived != null) {
    url += encodeValues`?includeArchived=${includeArchived}`;
  }
  return url;
};
export const schoolLearningPlanTemplatesDetail = (
  schoolId: string,
  id: string
) => encodeValues`/api/schools/${schoolId}/learning-plan-templates/${id}`;
export const schoolLearningPlans = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/learning-plans`;
export const schoolLearningPlansDetails = (schoolId: string, id: string) =>
  encodeValues`/api/schools/${schoolId}/learning-plans/${id}`;
export const schoolLearningPlansDocument = (
  schoolId: string,
  id: string,
  ext: string
) => encodeValues`/api/schools/${schoolId}/learning-plans/${id}.${ext}`;
export const schoolLearningPlansBulkDownload = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/learning-plans/bulk-download`;
export const schoolLearningPlansBulkDownloadCreate = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/learning-plans/bulk-download/create`;
export const schoolLearningPlansBulkDownloadStatus = (
  schoolId: string,
  downloadId: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/learning-plans/bulk-download/status`;
  url += encodeValues`?downloadId=${downloadId}`;
  return url;
};
export const schoolLearningPlansConfig = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/learning-plans/config`;
export const schoolLearningPlansPupil = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/learning-plans/pupils/${upn}`;
export const schoolLearningPlansPupilCurrent = (
  schoolId: string,
  upn: string
) =>
  encodeValues`/api/schools/${schoolId}/learning-plans/pupils/${upn}/current`;
export const schoolLogo = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/logo`;
export const schoolMergePupils = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/merge-pupils`;
export const schoolMergePupilsConflicts = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/merge-pupils/conflicts`;
export const schoolMtc = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/mtc`;
export const schoolMtcParse = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/mtc/parse`;
export const schoolMtcSave = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/mtc/save`;
export const schoolObjectives = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives`;
export const schoolObjectivesCollections = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections`;
export const schoolObjectivesCollection = (
  schoolId: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}`;
export const schoolObjectivesCollectionAssessments = (
  schoolId: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/assessments`;
export const schoolObjectivesCollectionAssessmentsHistory = (
  schoolId: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/assessments/history`;
export const schoolObjectivesCollectionAssessmentsSave = (
  schoolId: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/assessments/save`;
export const schoolObjectivesCollectionAssessmentsSummaries = (
  schoolId: string,
  collectionId: string,
  asOf: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/assessments/summaries`;
  url += encodeValues`?asOf=${asOf}`;
  return url;
};
export const schoolObjectivesCollectionAssessmentsExcel = (
  schoolId: string,
  collectionId: string,
  asOf: string,
  pupilQuery?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/assessments.xlsx`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`asOf=${asOf}`;
  anyQueryParams = true;
  if (pupilQuery != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`pupilQuery=${pupilQuery}`;
    anyQueryParams = true;
  }
  return url;
};
export const schoolObjectivesCollectionAttachments = (
  schoolId: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/attachments`;
export const schoolObjectivesCollectionAttachment = (
  schoolId: string,
  collectionId: string,
  attachmentId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/attachments/${attachmentId}`;
export const schoolObjectivesCollectionChart = (
  schoolId: string,
  collectionId: string,
  upn: string,
  academicYear: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/chart/${upn}`;
  url += encodeValues`?academicYear=${academicYear}`;
  return url;
};
export const schoolObjectivesCollectionWorkingWithin = (
  schoolId: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/${collectionId}/working-within`;
export const schoolObjectivesCollectionsArchive = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/archive`;
export const schoolObjectivesCollectionsExport = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives/collections/export`;
export const schoolObjectivesConfig = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives/config`;
export const schoolObjectivesSummative = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives/summative`;
export const schoolObjectivesZip = (
  schoolId: string,
  asOf: string,
  pupilQuery: string,
  collectionId?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/objectives/zip-pupil-reports`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`asOf=${asOf}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`pupilQuery=${pupilQuery}`;
  anyQueryParams = true;
  if (collectionId != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`collectionId=${collectionId}`;
    anyQueryParams = true;
  }
  return url;
};
export const schoolObjectivesZipJob = (schoolId: string, jobId: string) =>
  encodeValues`/api/schools/${schoolId}/objectives/zip-pupil-reports/${jobId}`;
export const schoolParentReports = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports`;
export const schoolParentReportsApprovalSettings = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/approval-settings`;
export const schoolParentReportsApprove = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/approve`;
export const schoolParentReportsDownloads = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/download`;
export const schoolParentReportsDownload = (schoolId: string, jobId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/download/${jobId}`;
export const schoolParentReportsEmail = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/email`;
export const schoolParentReportsEmailTemplate = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/email-template`;
export const schoolParentReportsFileNameTemplate = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/file-name-template`;
export const schoolParentReportsPlaceholderOptions = (
  schoolId: string,
  placeholder?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/parent-reports/placeholder-options`;
  if (placeholder != null) {
    url += encodeValues`?placeholder=${placeholder}`;
  }
  return url;
};
export const schoolParentReportsPlaceholderPreview = (
  schoolId: string,
  placeholder: string,
  upn: string,
  academicYear: string,
  term: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/parent-reports/placeholder-preview`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`placeholder=${placeholder}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`upn=${upn}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`academicYear=${academicYear}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`term=${term}`;
  anyQueryParams = true;
  return url;
};
export const schoolParentReportsPlaceholderPupilPhoto = (
  schoolId: string,
  width: string,
  height: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/parent-reports/placeholder/pupil-photo`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`width=${width}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`height=${height}`;
  anyQueryParams = true;
  return url;
};
export const schoolParentReportsRevokeAccess = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/revoke-access`;
export const schoolParentReportsSchema = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/schema`;
export const schoolParentReportsTemplates = (
  schoolId: string,
  includeDeletedWithActiveEmailLinks?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/parent-reports/templates`;
  if (includeDeletedWithActiveEmailLinks != null) {
    url += encodeValues`?includeDeletedWithActiveEmailLinks=${includeDeletedWithActiveEmailLinks}`;
  }
  return url;
};
export const schoolParentReportsTemplate = (
  schoolId: string,
  templateId: string
) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/templates/${templateId}`;
export const schoolParentReportsTemplateDocument = (
  schoolId: string,
  templateId: string
) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/templates/${templateId}.docx`;
export const schoolParentReportsUnapprove = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/unapprove`;
export const schoolParentReportsUpdateAttendance = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/parent-reports/update-attendance`;
export const schoolPhonics = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/phonics`;
export const schoolPhonicsCtf = (
  schoolId: string,
  ctfDestination: string,
  pupilQuery: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/phonics/ctf`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`ctfDestination=${ctfDestination}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`pupilQuery=${pupilQuery}`;
  anyQueryParams = true;
  return url;
};
export const schoolProvisionMapping = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping`;
export const schoolProvisionMappingProvisionGroups = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-groups`;
export const schoolProvisionMappingProvisionGroup = (
  schoolId: string,
  provisionGroupId: string
) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-groups/${provisionGroupId}`;
export const schoolProvisionMappingProvisionGroupNotes = (
  schoolId: string,
  provisionGroupId: string
) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-groups/${provisionGroupId}/notes`;
export const schoolProvisionMappingProvisionGroupNote = (
  schoolId: string,
  provisionGroupId: string,
  provisionGroupNoteId: string
) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-groups/${provisionGroupId}/notes/${provisionGroupNoteId}`;
export const schoolProvisionMappingProvisionMap = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-map`;
export const schoolProvisionMappingProvisionTypes = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-types`;
export const schoolProvisionMappingProvisionType = (
  schoolId: string,
  provisionTypeId: string
) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/provision-types/${provisionTypeId}`;
export const schoolProvisionMappingSupervisors = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/provision-mapping/supervisors`;
export const schoolPupilBatchSearch = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/pupil-batch-search`;
export const schoolPupilNameSettings = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/pupil-name-settings`;
export const schoolPupilQueryMetadata = (
  schoolId: string,
  academicYear: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/pupil-query-metadata`;
  url += encodeValues`?academicYear=${academicYear}`;
  return url;
};
export const schoolPupilSearch = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/pupil-search`;
export const schoolPupils = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/pupils`;
export const schoolPupilsAddDemo = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/add-demo`;
export const schoolPupilsHighlighters = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/highlighter`;
export const schoolPupil = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}`;
export const schoolPupilAssessments = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/assessments`;
export const schoolPupilAssessment = (
  schoolId: string,
  upn: string,
  assessmentId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/assessments/${assessmentId}`;
export const schoolPupilAssessmentsChart = (
  schoolId: string,
  upn: string,
  name: string,
  subject: string,
  academicYear?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/pupils/${upn}/assessments-chart`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`name=${name}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`subject=${subject}`;
  anyQueryParams = true;
  if (academicYear != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`academicYear=${academicYear}`;
    anyQueryParams = true;
  }
  return url;
};
export const schoolPupilAttendance = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/attendance`;
export const schoolPupilAttendanceSessions = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/attendance-sessions`;
export const schoolPupilDelete = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/delete`;
export const schoolPupilExport = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/export`;
export const schoolPupilEyfsProfileCharacteristics = (
  schoolId: string,
  upn: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/eyfs-profile-characteristics`;
export const schoolPupilNotes = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/notes`;
export const schoolPupilNote = (
  schoolId: string,
  upn: string,
  noteId: string
) => encodeValues`/api/schools/${schoolId}/pupils/${upn}/notes/${noteId}`;
export const schoolPupilObjectives = (
  schoolId: string,
  upn: string,
  collectionId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/objectives/${collectionId}`;
export const schoolPupilParentReport = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string,
  templateId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/${templateId}`;
export const schoolPupilParentReportApprove = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string,
  templateId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/${templateId}/approve`;
export const schoolPupilParentReportFile = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string,
  templateId: string,
  ext: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/${templateId}/report.${ext}`;
export const schoolPupilParentReportUnapprove = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string,
  templateId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/${templateId}/unapprove`;
export const schoolPupilParentReportUpdateAttendance = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string,
  templateId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/${templateId}/update-attendance`;
export const schoolPupilParentReportComments = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/comments`;
export const schoolPupilParentReportCommentsImage = (
  schoolId: string,
  upn: string,
  academicYear: string,
  term: string,
  commentId: string
) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/parent-reports/${academicYear}/${term}/comments/${commentId}/image`;
export const schoolPupilPhoto = (
  schoolId: string,
  upn: string,
  hash: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/pupils/${upn}/photo`;
  url += encodeValues`?hash=${hash}`;
  return url;
};
export const schoolPupilProfile = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/profile`;
export const schoolPupilProvisionGroups = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/provision-groups`;
export const schoolPupilUpdate = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/update`;
export const schoolPupilYearGroup = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/year-group`;
export const schoolPupilYearGroups = (schoolId: string, upn: string) =>
  encodeValues`/api/schools/${schoolId}/pupils/${upn}/year-groups`;
export const schoolReactivatePupilContactEmail = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reactivate-pupil-contact-email`;
export const schoolReports = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports`;
export const schoolReportsAttainmentOverview = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/attainment-overview`;
export const schoolReportsAttendance = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/attendance`;
export const schoolReportsDemographics = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/demographics`;
export const schoolReportsLadderChart = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/ladder-chart.svg`;
export const schoolReportsObjectives = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/objectives`;
export const schoolReportsObjectivesSettings = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/objectives/settings`;
export const schoolReportsProgressMatrix = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/progress-matrix`;
export const schoolReportsProgressMatrixExcel = (
  schoolId: string,
  ext: string
) => encodeValues`/api/schools/${schoolId}/reports/progress-matrix.${ext}`;
export const schoolReportsProgressOverview = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/progress-overview`;
export const schoolReportsScatterChart = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/scatter-chart`;
export const schoolReportsStatutoryAssessments = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/reports/statutory-assessments`;
export const schoolSatTeacherAssessments = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/sat-teacher-assessments`;
export const schoolSatTeacherAssessmentsKs1Ctf = (
  schoolId: string,
  ctfDestination: string,
  pupilQuery: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/sat-teacher-assessments/ks1-ctf`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`ctfDestination=${ctfDestination}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`pupilQuery=${pupilQuery}`;
  anyQueryParams = true;
  return url;
};
export const schoolSatTeacherAssessmentsKs2Ctf = (
  schoolId: string,
  ctfDestination: string,
  pupilQuery: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/sat-teacher-assessments/ks2-ctf`;
  let anyQueryParams = false;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`ctfDestination=${ctfDestination}`;
  anyQueryParams = true;
  url += anyQueryParams ? "&" : "?";
  url += encodeValues`pupilQuery=${pupilQuery}`;
  anyQueryParams = true;
  return url;
};
export const schoolSmartgradeImport = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/smartgrade-import`;
export const schoolSmartgradeImportStatus = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/smartgrade-import/status`;
export const schoolSmartgradeImportStatusBatch = (
  schoolId: string,
  batchId: string
) =>
  encodeValues`/api/schools/${schoolId}/smartgrade-import/status/batches/${batchId}`;
export const schoolStaff = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/staff`;
export const schoolSubscription = (schoolId: string, method: string) =>
  encodeValues`/api/schools/${schoolId}/subscription/${method}`;
export const schoolTableRender = (
  schoolId: string,
  pupilQuery?: string,
  sort?: string,
  tableParams?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/table-render`;
  let anyQueryParams = false;
  if (pupilQuery != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`pupilQuery=${pupilQuery}`;
    anyQueryParams = true;
  }
  if (sort != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`sort=${sort}`;
    anyQueryParams = true;
  }
  if (tableParams != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`tableParams=${tableParams}`;
    anyQueryParams = true;
  }
  return url;
};
export const schoolTableTemplatePresets = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/table-template-presets`;
export const schoolTableTemplates = (
  schoolId: string,
  owner?: string,
  page?: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/table-templates`;
  let anyQueryParams = false;
  if (owner != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`owner=${owner}`;
    anyQueryParams = true;
  }
  if (page != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`page=${page}`;
    anyQueryParams = true;
  }
  return url;
};
export const schoolTableTemplate = (
  schoolId: string,
  tableTemplateId: string
) => encodeValues`/api/schools/${schoolId}/table-templates/${tableTemplateId}`;
export const schoolTagInstances = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/tags/instances`;
export const schoolTagNames = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/tags/names`;
export const schoolTagRename = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/tags/rename`;
export const schoolTapestry2021 = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/tapestry-2021`;
export const schoolTapestry2021Parse = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/tapestry-2021/parse-csv`;
export const schoolTapestry2021Save = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/tapestry-2021/save-assessments`;
export const schoolTargetTrackerImport = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/target-tracker-import`;
export const schoolTargetTrackerImportParse = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/target-tracker-import/parse`;
export const schoolTargetTrackerImportStatus = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/target-tracker-import/status`;
export const schoolTerms = (schoolId: string, academicYear: string) =>
  encodeValues`/api/schools/${schoolId}/terms/${academicYear}`;
export const schoolUploadedFiles = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/uploaded-files`;
export const schoolUploadedFilesFile = (schoolId: string, fileId: string) =>
  encodeValues`/api/schools/${schoolId}/uploaded-files/${fileId}`;
export const schoolUploadedFilesZip = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/uploaded-files/download-zip`;
export const schoolUsers = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/users`;
export const schoolUsersEmailAddress = (
  schoolId: string,
  emailAddress: string
) => {
  let url = encodeValues`/api/schools/${schoolId}/users`;
  url += encodeValues`?emailAddress=${emailAddress}`;
  return url;
};
export const schoolUsersReactivateEmail = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/users/reactivate-email`;
export const schoolUsersResendInvites = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/users/resend-invites`;
export const schoolWonde = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde`;
export const schoolWondeAccess = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/access`;
export const schoolWondeCapture = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/capture`;
export const schoolWondeConnect = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/connect`;
export const schoolWondeDeauthorise = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/deauthorise`;
export const schoolWondeDisable = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/disable`;
export const schoolWondeEnable = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/enable`;
export const schoolWondeManualConnect = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/manual-connect`;
export const schoolWondeSettings = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/settings`;
export const schoolWondeSync = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/wonde/start`;
export const schoolYearGroupRange = (schoolId: string) =>
  encodeValues`/api/schools/${schoolId}/year-group-range`;
export const ses = () => encodeValues`/api/ses`;
export const sesBounce = () => encodeValues`/api/ses/bounce`;
export const sesComplaint = () => encodeValues`/api/ses/complaint`;
export const sesDelivery = () => encodeValues`/api/ses/delivery`;
export const sesNewsletter = () => encodeValues`/api/ses/newsletter`;
export const supportVerification = () =>
  encodeValues`/api/support-verification`;
export const test2 = () => encodeValues`/api/test2`;
export const trust = (trustId: string) => encodeValues`/api/trusts/${trustId}`;
export const trustAccessRecord = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/access-record`;
export const trustAdminMarkSchemes = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes`;
export const trustAdminMarkScheme = (trustId: string, markSchemeId: string) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/${markSchemeId}`;
export const trustAdminMarkSchemeJson = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/${markSchemeId}/json`;
export const trustAdminMarkSchemesDefault = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/default`;
export const trustAdminMarkSchemesDiff = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/diff`;
export const trustAdminMarkSchemesJson = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/json`;
export const trustAdminMarkSchemesAge = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Age/${markSchemeId}`;
export const trustAdminMarkSchemesAverageDepth = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/AverageDepth/${markSchemeId}`;
export const trustAdminMarkSchemesBand = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Band/${markSchemeId}`;
export const trustAdminMarkSchemesBandConversionStatus = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Band/${markSchemeId}/conversion-status`;
export const trustAdminMarkSchemesBandConvert = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Band/${markSchemeId}/convert`;
export const trustAdminMarkSchemesDecimal = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Decimal/${markSchemeId}`;
export const trustAdminMarkSchemesDevelopmentMatters = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/DevelopmentMatters/${markSchemeId}`;
export const trustAdminMarkSchemesDevelopmentMattersConversionStatus = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/DevelopmentMatters/${markSchemeId}/conversion-status`;
export const trustAdminMarkSchemesDevelopmentMattersConvert = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/DevelopmentMatters/${markSchemeId}/convert`;
export const trustAdminMarkSchemesKsSatScaledScores = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/KsSatScaledScores/${markSchemeId}`;
export const trustAdminMarkSchemesMtc = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Mtc/${markSchemeId}`;
export const trustAdminMarkSchemesPhonics = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Phonics/${markSchemeId}`;
export const trustAdminMarkSchemesPitaBands = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/PitaBands/${markSchemeId}`;
export const trustAdminMarkSchemesPitaBandsBand = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/PitaBands/${markSchemeId}/Band`;
export const trustAdminMarkSchemesRawScore = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/RawScore/${markSchemeId}`;
export const trustAdminMarkSchemesStandardisedScores = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/StandardisedScores/${markSchemeId}`;
export const trustAdminMarkSchemesSteps = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Steps/${markSchemeId}`;
export const trustAdminMarkSchemesStepsExcel = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Steps/${markSchemeId}/excel`;
export const trustAdminMarkSchemesStepsStep = (
  trustId: string,
  markSchemeId: string
) =>
  encodeValues`/api/trusts/${trustId}/admin/mark-schemes/types/Steps/${markSchemeId}/step`;
export const trustSubjects = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/admin/subjects`;
export const trustAssessmentFrequency = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-frequency`;
export const trustAssessmentTransfer = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-transfer`;
export const trustAssessmentTransferPupils = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-transfer/pupils`;
export const trustAssessmentTransferSchools = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-transfer/schools`;
export const trustAssessmentTransferStartCopy = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-transfer/start-copy`;
export const trustAssessmentTransferStatus = (
  trustId: string,
  statusId: string
) =>
  encodeValues`/api/trusts/${trustId}/assessment-transfer/status/${statusId}`;
export const trustAssessmentTypes = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-types`;
export const trustAssessmentType = (
  trustId: string,
  assessmentTypeName: string
) =>
  encodeValues`/api/trusts/${trustId}/assessment-types/${assessmentTypeName}`;
export const trustAssessmentTypeComponents = (
  trustId: string,
  assessmentTypeName: string
) =>
  encodeValues`/api/trusts/${trustId}/assessment-types/${assessmentTypeName}/components`;
export const trustAssessmentTypeComponent = (
  trustId: string,
  assessmentTypeName: string,
  assessmentComponentName: string
) =>
  encodeValues`/api/trusts/${trustId}/assessment-types/${assessmentTypeName}/components/${assessmentComponentName}`;
export const trustAssessmentTypesCreate = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-types/create`;
export const trustAssessmentTypesRenameStatus = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/assessment-types/rename-status`;
export const trustAttainmentEvaluationDescriptions = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/attainment-evaluation-descriptions`;
export const trustAttendanceThresholds = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/attendance-thresholds`;
export const trustAudit = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/audit-entries`;
export const trustFile = (trustId: string, path: string) =>
  encodeValues`/api/trusts/${trustId}/files/${doNotEncode(path)}`;
export const trustHeadlineReports = (trustId: string, owner?: string) => {
  let url = encodeValues`/api/trusts/${trustId}/headline-reports`;
  if (owner != null) {
    url += encodeValues`?owner=${owner}`;
  }
  return url;
};
export const trustHeadlineReport = (trustId: string, reportId: string) =>
  encodeValues`/api/trusts/${trustId}/headline-reports/${reportId}`;
export const trustHeadlineReportsExcel = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/headline-reports/excel`;
export const trustHeadlineReportsLinks = (
  trustId: string,
  owner: string,
  page?: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/headline-reports/links/${owner}`;
  if (page != null) {
    url += encodeValues`?page=${page}`;
  }
  return url;
};
export const trustHeadlineReportsRender = (
  trustId: string,
  isPreview?: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/headline-reports/render`;
  if (isPreview != null) {
    url += encodeValues`?isPreview=${isPreview}`;
  }
  return url;
};
export const trustHeadlineReportsRenderJob = (
  trustId: string,
  jobId: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/headline-reports/render`;
  url += encodeValues`?jobId=${jobId}`;
  return url;
};
export const trustKeyGroups = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/key-groups`;
export const trustLayout = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/layout`;
export const trustMosaic = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/mosaic`;
export const trustMosaicReports = (
  trustId: string,
  duplicateReportId?: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/mosaic/reports`;
  if (duplicateReportId != null) {
    url += encodeValues`?duplicateReportId=${duplicateReportId}`;
  }
  return url;
};
export const trustMosaicReport = (trustId: string, reportId: string) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}`;
export const trustMosaicReportFigureOrder = (
  trustId: string,
  reportId: string
) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}/figure-order`;
export const trustMosaicReportFigures = (trustId: string, reportId: string) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}/figures`;
export const trustMosaicReportFigure = (
  trustId: string,
  reportId: string,
  figureId: string
) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}/figures/${figureId}`;
export const trustMosaicReportFigureComments = (
  trustId: string,
  reportId: string,
  figureId: string
) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}/figures/${figureId}/comments`;
export const trustMosaicReportFigureDuplicate = (
  trustId: string,
  reportId: string,
  figureId: string
) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}/figures/${figureId}/duplicate`;
export const trustMosaicReportFigureRefresh = (
  trustId: string,
  reportId: string,
  figureId: string
) =>
  encodeValues`/api/trusts/${trustId}/mosaic/reports/${reportId}/figures/${figureId}/refresh`;
export const trustObjectives = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/objectives`;
export const trustObjectivesCollections = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/objectives/collections`;
export const trustObjectivesCollection = (
  trustId: string,
  collectionId: string
) =>
  encodeValues`/api/trusts/${trustId}/objectives/collections/${collectionId}`;
export const trustObjectivesCollectionsArchive = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/objectives/collections/archive`;
export const trustObjectivesCollectionsExport = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/objectives/collections/export`;
export const trustObjectivesCollectionsMoveToTrust = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/objectives/collections/move-to-trust`;
export const trustObjectivesConfig = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/objectives/config`;
export const trustPrivateMode = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/private-mode`;
export const trustPupilQueryMetadata = (
  trustId: string,
  academicYear: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/pupil-query-metadata`;
  url += encodeValues`?academicYear=${academicYear}`;
  return url;
};
export const trustReports = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports`;
export const trustReportsAttainmentOverview = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/attainment-overview`;
export const trustReportsAttendance = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/attendance`;
export const trustReportsDemographics = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/demographics`;
export const trustReportsObjectives = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/objectives`;
export const trustReportsObjectivesSettings = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/objectives/settings`;
export const trustReportsProgressMatrix = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/progress-matrix`;
export const trustReportsProgressMatrixExcel = (trustId: string, ext: string) =>
  encodeValues`/api/trusts/${trustId}/reports/progress-matrix.${ext}`;
export const trustReportsProgressOverview = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/progress-overview`;
export const trustReportsScatterChart = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/scatter-chart`;
export const trustReportsStatutoryAssessments = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/reports/statutory-assessments`;
export const trustReportsStatutoryAssessmentsSchools = (
  trustId: string,
  statResultType: string
) =>
  encodeValues`/api/trusts/${trustId}/reports/statutory-assessments/schools/${statResultType}`;
export const trustSmartgradeImport = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/smartgrade-import`;
export const trustSmartgradeImportStatus = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/smartgrade-import/status`;
export const trustSmartgradeImportStatusBatch = (
  trustId: string,
  batchId: string
) =>
  encodeValues`/api/trusts/${trustId}/smartgrade-import/status/batches/${batchId}`;
export const trustSubscription = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/subscription`;
export const trustSubscriptionSignUp = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/subscription/sign-up`;
export const trustTableRender = (
  trustId: string,
  pupilQuery?: string,
  sort?: string,
  tableParams?: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/table-render`;
  let anyQueryParams = false;
  if (pupilQuery != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`pupilQuery=${pupilQuery}`;
    anyQueryParams = true;
  }
  if (sort != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`sort=${sort}`;
    anyQueryParams = true;
  }
  if (tableParams != null) {
    url += anyQueryParams ? "&" : "?";
    url += encodeValues`tableParams=${tableParams}`;
    anyQueryParams = true;
  }
  return url;
};
export const trustTableRenderStatus = (trustId: string, jobId: string) => {
  let url = encodeValues`/api/trusts/${trustId}/table-render/status`;
  url += encodeValues`?jobId=${jobId}`;
  return url;
};
export const trustTableTemplatePresets = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/table-template-presets`;
export const trustTableTemplates = (trustId: string, page?: string) => {
  let url = encodeValues`/api/trusts/${trustId}/table-templates`;
  if (page != null) {
    url += encodeValues`?page=${page}`;
  }
  return url;
};
export const trustTableTemplate = (trustId: string, tableTemplateId: string) =>
  encodeValues`/api/trusts/${trustId}/table-templates/${tableTemplateId}`;
export const trustTerms = (trustId: string, academicYear: string) =>
  encodeValues`/api/trusts/${trustId}/terms/${academicYear}`;
export const trustUploadedFiles = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/uploaded-files`;
export const trustUploadedFilesFile = (trustId: string, fileId: string) =>
  encodeValues`/api/trusts/${trustId}/uploaded-files/${fileId}`;
export const trustUploadedFilesZip = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/uploaded-files/download-zip`;
export const trustUsers = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/users`;
export const trustUsersEmailAddress = (
  trustId: string,
  emailAddress: string
) => {
  let url = encodeValues`/api/trusts/${trustId}/users`;
  url += encodeValues`?emailAddress=${emailAddress}`;
  return url;
};
export const trustUsersReactivateEmail = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/users/reactivate-email`;
export const trustUsersResendInvites = (trustId: string) =>
  encodeValues`/api/trusts/${trustId}/users/resend-invites`;
export const wonde = () => encodeValues`/api/wonde`;
export const wondeWebhook = () => encodeValues`/api/wonde/webhook`;
